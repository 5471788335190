<template>
  <div>
    <v-dialog
      :value="value"
      :fullscreen="!disableFullscreen && $vuetify.breakpoint.smAndDown"
      scrollable
      max-width="700px"
      v-on="$listeners"
    >
      <customer-password-reset-card
        :loading="loading"
        @submit="handleSubmit"
        @close="$emit('input', false)"
      >
        <base-alert v-if="errors.resetPassword.length" type="error" class="mt-5"
          >{{ $t('customer.passwordReset.notification.error') }}
        </base-alert>
        <base-alert v-if="success" type="success" class="mt-5">
          {{ $t('customer.passwordReset.notification.messageSent') }}
        </base-alert>
      </customer-password-reset-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api'
import CustomerPasswordResetCard from '~/components/customer/login/CustomerPasswordResetCard'
import { useUser } from '~/composables'
import BaseAlert from '~/components/base/alert/BaseAlert'
export default {
  name: 'CustomerPasswordResetContainer',
  components: { BaseAlert, CustomerPasswordResetCard },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disableFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const { errors, resetPassword } = useUser(root)
    const loading = ref(false)
    const success = ref(false)

    const handleSubmit = async ({ email }) => {
      loading.value = true
      errors.resetPassword = []
      success.value = false

      success.value = await resetPassword({
        email,
        storefrontUrl: root.$config.appUrl,
      })
      loading.value = false
    }

    return {
      errors,
      success,
      loading,
      resetPassword,
      handleSubmit,
    }
  },
}
</script>
