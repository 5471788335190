<template>
  <base-dialog-card
    :title="$t('customer.passwordReset.title')"
    :action-text="$t('actions.back')"
    @click:close="$emit('close')"
  >
    <slot></slot>

    <p class="text-body-3 mt-5">
      {{ $t('customer.passwordReset.message') }}
    </p>

    <validation-observer ref="form">
      <v-form>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('fields.email')"
          rules="required|email"
        >
          <base-input-text-field
            v-model.trim="email"
            class="mt-5"
            autocomplete="username"
            hide-details="auto"
            :label="$t('fields.email')"
            :error-messages="errors"
          ></base-input-text-field>
        </validation-provider>
      </v-form>
    </validation-observer>
    <base-button
      block
      large
      rounded
      class="mt-7 checkout-primary white--text"
      :loading="loading"
      @click="handleSubmit"
      >{{ $t('commons.resetPassword') }}</base-button
    >
  </base-dialog-card>
</template>

<script>
import BaseInputTextField from '~/components/base/input/BaseInputTextField'
import BaseDialogCard from '~/components/base/BaseDialogCard'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'CustomerPasswordResetCard',
  components: { BaseButton, BaseDialogCard, BaseInputTextField },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: undefined,
    }
  },
  methods: {
    async handleSubmit() {
      if (!(await this.$refs.form.validate())) {
        return
      }

      this.$emit('submit', {
        email: this.email,
      })
    },
  },
}
</script>
