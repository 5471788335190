<template>
  <div>
    <customer-login-form
      :loading="loading"
      :hide-pay-pal="hidePayPal"
      @login="invokeLogin"
      @click:password-reset="pwResetDialog = true"
      @click:paypal="$emit('click:paypal')"
    >
      <template #prepend>
        <div
          v-if="errors.login.length"
          class="text-body-5 pink-berry--text mb-3"
        >
          {{ $t('customer.login.form.notFound') }}
        </div>
      </template>
    </customer-login-form>
    <customer-password-reset-container
      v-model="pwResetDialog"
      :disable-fullscreen="disableFullscreen"
    ></customer-password-reset-container>
  </div>
</template>

<script>
import { useUser } from '~/composables'
import CustomerLoginForm from '~/components/customer/login/CustomerLoginForm'
import CustomerPasswordResetContainer from '~/components/customer/login/CustomerPasswordResetContainer'

export default {
  name: 'CustomerLoginFormContainer',
  components: { CustomerPasswordResetContainer, CustomerLoginForm },
  props: {
    hidePayPal: {
      type: Boolean,
      default: false,
    },
    disableFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const { login, loading, errors } = useUser(root)

    return {
      login,
      loading,
      errors,
    }
  },
  data() {
    return {
      pwResetDialog: false,
    }
  },
  methods: {
    async invokeLogin({ username, password }) {
      this.errors.login = []

      const successful = await this.login({
        username,
        password,
      })

      if (successful) {
        this.$emit('successful')
      }
    },
  },
}
</script>
