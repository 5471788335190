<template>
  <v-card flat class="base-dialog-card">
    <v-card-title
      v-if="!hideTitle"
      class="
        beige-pastel-light
        text-h4 text-md-h2
        d-flex
        align-center
        justify-center
        pa-0
      "
    >
      <div :class="[hideCloseButton ? 'mx-auto' : 'ml-md-auto']">
        {{ title }}
      </div>
      <base-button
        v-if="!hideCloseButton"
        icon
        class="ml-md-auto mr-7 hidden-sm-and-down"
        @click="$emit('click:close')"
      >
        <base-icon mdi="close"></base-icon>
      </base-button>
    </v-card-title>
    <v-card-text :class="contentClass" class="grey-darken-4--text">
      <slot></slot>
    </v-card-text>
    <slot name="append"></slot>
    <v-card-actions v-if="!hideActions" class="pa-0 hidden-md-and-up">
      <slot name="actions">
        <base-button
          block
          large
          class="grey-lighten-3 text-body-4"
          @click="$emit('click:close')"
        >
          <base-icon mdi="close" small class="mr-1"></base-icon>
          {{ actionText }}
        </base-button>
      </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'
export default {
  name: 'BaseDialogCard',
  components: { BaseButton, BaseIcon },
  props: {
    contentClass: {
      type: String,
      default: 'pa-5',
    },
    actionText: {
      type: String,
      default: 'Schließen',
    },
    title: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.base-dialog-card {
  > .v-card__title {
    height: 80px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: 50px;
    }
  }
}
</style>
