<template>
  <validation-observer ref="form">
    <v-form class="customer-login-form">
      <slot name="prepend"></slot>
      <validation-provider
        v-slot="{ errors }"
        :name="$t('customer.login.form.email')"
        rules="required|email"
        mode="lazy"
      >
        <base-input-text-field
          v-model.trim="form.username"
          autocomplete="username"
          hide-details
          type="email"
          :label="$t('customer.login.form.email')"
          :error-messages="errors"
        ></base-input-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        :name="$t('customer.login.form.password')"
        rules="required"
      >
        <base-input-text-field
          v-model="form.password"
          class="mt-3"
          type="password"
          hide-details
          autocomplete="current-password"
          :label="$t('customer.login.form.password')"
          :error-messages="errors"
        ></base-input-text-field>
      </validation-provider>
      <div
        class="text-body-5 grey-darken-4--text text-decoration-none mt-3"
        role="button"
        @click="$emit('click:password-reset')"
      >
        {{ $t('commons.forgetPassword') }}
      </div>

      <div class="d-flex justify-end justify-sm-center">
        <div class="d-flex justify-end flex-column customer-login-form__box">
          <base-button
            large
            rounded
            dynamic
            class="mt-6 checkout-primary white--text mx-auto"
            :loading="loading"
            @click="invokeSubmit"
            >{{ $t('actions.signIn') }}
          </base-button>

          <div
            v-if="!hidePayPal"
            class="mt-5 text-right d-inline-flex justify-end"
          >
            {{ $t('commons.or') }}

            <payment-paypal-express-checkout-button
              style="min-width: 150px"
              class="ml-3"
              @event:create-order="$emit('click:paypal')"
            ></payment-paypal-express-checkout-button>
          </div>
        </div>
      </div>
    </v-form>
  </validation-observer>
</template>

<script>
import PaymentPaypalExpressCheckoutButton from '~/components/payment/paypal/PaymentPaypalExpressCheckoutButton'
import BaseInputTextField from '~/components/base/input/BaseInputTextField'
import BaseButton from '~/components/base/button/BaseButton'
export default {
  name: 'CustomerLoginForm',
  components: {
    BaseButton,
    BaseInputTextField,
    PaymentPaypalExpressCheckoutButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hidePayPal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        username: undefined,
        password: undefined,
      },
    }
  },
  methods: {
    async invokeSubmit() {
      if (!(await this.$refs.form.validate())) {
        return
      }

      this.$emit('login', {
        username: this.form.username,
        password: this.form.password,
      })
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.customer-login-form {
  &__box {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 100%;
    }
  }
}
</style>
